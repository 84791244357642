@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts/sweet-sans-pro/style.css';
@import 'fonts/CanvasInline-Reg/style.css';

/* body {
    font-family: 'Sweet Sans Pro Regular', sans-serif;
} */

input {
    color: #000;
}
