/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Sweet Sans Pro Regular'), url('SweetSansProRegular.woff') format('woff');
    }


    /* @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: italic;
    font-weight: normal;
    src: local('Sweet Sans Pro Italic'), url('SweetSansProItalic.woff') format('woff');
    } */


    @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 100;
    src: local('Sweet Sans Pro Hairline'), url('SweetSansProHairline.woff') format('woff');
    }


    /* @font-face {
    font-family: 'Sweet Sans Pro Hairline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Sweet Sans Pro Hairline Italic'), url('SweetSansProHairlineItalic.woff') format('woff');
    } */


    @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: local('Sweet Sans Pro Extra Thin'), url('SweetSansProExThin.woff') format('woff');
    }


    /* @font-face {
    font-family: 'Sweet Sans Pro Extra Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Sweet Sans Pro Extra Thin Italic'), url('SweetSansProExThinItalic.woff') format('woff');
    } */


    @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Sweet Sans Pro Thin'), url('SweetSansProThin.woff') format('woff');
    }


    /* @font-face {
    font-family: 'Sweet Sans Pro Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Sweet Sans Pro Thin Italic'), url('SweetSansProThinItalic.woff') format('woff');
    } */


    @font-face {
    font-family: 'Sweet Sans Pro Extra Light';
    font-style: normal;
    font-weight: 400;
    src: local('Sweet Sans Pro Extra Light'), url('SweetSansProExLight.woff') format('woff');
    }


    /* @font-face {
    font-family: 'Sweet Sans Pro Extra Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Sweet Sans Pro Extra Light Italic'), url('SweetSansProExLightItalic.woff') format('woff');
    } */


    @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 500;
    src: local('Sweet Sans Pro Light'), url('SweetSansProLight.woff') format('woff');
    }

/*
    @font-face {
    font-family: 'Sweet Sans Pro Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Sweet Sans Pro Light Italic'), url('SweetSansProLightItalic.woff') format('woff');
    } */


    @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Sweet Sans Pro Medium'), url('SweetSansProMedium.woff') format('woff');
    }


    /* @font-face {
    font-family: 'Sweet Sans Pro Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Sweet Sans Pro Medium Italic'), url('SweetSansProMediumItalic.woff') format('woff');
    } */


    @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 800;
    src: local('Sweet Sans Pro Bold'), url('SweetSansProBold.woff') format('woff');
    }


    /* @font-face {
    font-family: 'Sweet Sans Pro Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Sweet Sans Pro Bold Italic'), url('SweetSansProBoldItalic.woff') format('woff');
    } */


    @font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 900;
    src: local('Sweet Sans Pro Heavy'), url('SweetSansProHeavy.woff') format('woff');
    }


    /* @font-face {
    font-family: 'Sweet Sans Pro Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Sweet Sans Pro Heavy Italic'), url('SweetSansProHeavyItalic.woff') format('woff');
    } */