@font-face{
    font-family: "CanvasInline-Reg";
    src: url("CanvasInline-Reg.eot");
    src: url("CanvasInline-Reg.eot?#iefix")format("embedded-opentype"),
        url("CanvasInline-Reg.woff")format("woff"),
        url("CanvasInline-Reg.woff2")format("woff2"),
        url("CanvasInline-Reg.ttf")format("truetype"),
        url("CanvasInline-Reg.svg#CanvasInline-Reg")format("svg");
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}